import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 分页查询批属性规则
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11758
 */

export function ruleBatchAttribute(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/rule_batch_attribute/page', data, {
    params,
  });
}
/**
 * @description: 批属性规则 新增
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11750
 */

export function ruleBatchAttributeInsert(data) {
  return wmsPlusHttp.post('/warehouse_management_system/rule_batch_attribute/insert', data);
}
/**
 * @description: 批属性规则 修改
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11752
 */

export function ruleBatchAttributeUpdate(data) {
  return wmsPlusHttp.post('/warehouse_management_system/rule_batch_attribute/update', data);
}
/**
 * @description: 批属性规则 根据ID删除
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11756
 */

export function ruleBatchAttributeDelete(data) {
  return wmsPlusHttp.post('/warehouse_management_system/rule_batch_attribute/delete', data);
}
/**
 * @description: 启用禁用批属性规则
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11760
 */

export function ruleBatchAttributeEnable(data) {
  return wmsPlusHttp.post('/warehouse_management_system/rule_batch_attribute/enable', data);
}

/**
 * @description: 批次字段列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/385/interface/api/12622
 *
 */
export function batchFieldList(data) {
  return wmsPlusHttp.post('/warehouse_management_system/batch_field/list', data, {});
}
/**
 * @description:查询货主是否有默认的批属性规则
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/12400
 */
export function existDefaultRule(data) {
  return wmsPlusHttp.post('/warehouse_management_system/rule_batch_attribute/exist_default_rule', data);
}

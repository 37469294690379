import { FormType } from '@/constant/form';

export default [{
  label: '批属性',
  prop: 'fieldCode',
  width: 180,
}, {
  label: '操作',
  prop: 'opera',
  width: 180,
}];
export const formFileds = [
  {
    label: '规则编码',
    prop: 'ruleCode',
    colAttrs: {
      span: 12,
    },
    component: FormType.INPUT,
  },
  {
    label: '规则名称',
    prop: 'ruleName',
    colAttrs: {
      span: 12,
    },
    component: FormType.INPUT,
  },
  {
    label: '指定货主',
    prop: 'shipperId',
    colAttrs: {
      span: 12,
    },
  },
  {
    label: '',
    prop: 'customerDefaultRule',
    colAttrs: {
      span: 12,
    },
  },
  {
    label: '备注',
    prop: 'description',
    colAttrs: {
      span: 24,
    },
    component: FormType.INPUT,
    componentAttrs: {
      showWordLimit: true,
      maxlength: 500,
      type: 'textarea',
    },
  },
  {
    label: '批属性',
    prop: 'batchAttribute',
  },
];

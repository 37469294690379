<template>
  <pl-block>
    <PlForm
      v-model="formData"
      mode="search"
      :fields="serchFields"
      @submit="handleQueryFrom"
      @reset="handleQueryFrom"
    >
      <template #shipperId>
        <nh-shipper-selector
          v-model:shipperId="formData.shipperId"
        />
      </template>
    </PlForm>
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :columns="TABLECOLUMN"
      :show-table-setting="true"
    >
      <template #tableHeaderRight>
        <nh-button
          :track="{
            trackName: '配置管理/批属性规则/[新建]'
          }"
          @click="handleAdd"
        >
          新建
        </nh-button>
      </template>
      <template #action="{row}">
        <nh-button type="text" @click="handlePreview(row)">
          查看
        </nh-button>
        <nh-button type="text" @click="handleEdit(row)">
          修改
        </nh-button>
        <nh-button
          v-if="row.status === STATUS_ENUM.ENABLED"
          type="text"
          @click="handleEnable(row, STATUS_ENUM.DISABLED)"
        >
          失效
        </nh-button>
        <nh-button
          v-if="row.status === STATUS_ENUM.DISABLED"
          type="text"
          @click="handleEnable(row, STATUS_ENUM.ENABLED)"
        >
          生效
        </nh-button>
      </template>
    </PlTable>
  </pl-block>
  <Defails
    ref="details"
    v-loading="loading.loadBatchFieldList"
    :attribute-codes="attributeCodes"
    @success="handleDetailsSuccess"
  />
</template>
<script>
import loadingMixin from '@thales/loading';
import { TABLECOLUMN, serchFields, STATUS_ENUM } from './fileds';
import {
  ruleBatchAttribute, ruleBatchAttributeEnable, batchFieldList,
} from './api';
import defails from './details/details.vue';

export default {
  name: 'ConfigManagement',
  components: {
    Defails: defails,
  },
  mixins: [loadingMixin],
  data() {
    return {
      serchFields,
      TABLECOLUMN,
      STATUS_ENUM,
      tableData: [],
      formData: {
        shipperId: null,
      },
      loading: {
        getTableData: false,
        loadBatchFieldList: false,
      },
      attributeCodes: [],
    };
  },
  watch: {
    pagination() {
      this.getTableData();
    },
  },
  async created() {
    await this.loadBatchFieldList();
    await this.getTableData();
  },
  methods: {
    handleQueryFrom(data) {
      this.pagination.page = 1;
      this.formData = data;
      this.getTableData();
    },
    async getTableData() {
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await ruleBatchAttribute(pagination, this.formData);
      this.tableData = resp.records.map((item) => ({
        ...item,
      }));
      this.pagination.total = resp.total;
    },
    async handleEnable(rowData, status) {
      const data = new FormData();
      data.append('id', rowData.id);
      data.append('statusEnum', status);
      await this.$confirm(`你确定要${status === STATUS_ENUM.DISABLED ? '禁用' : '启用'}该规则?`, {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
      });

      await ruleBatchAttributeEnable(data);
      await this.$message({
        type: 'success',
        message: '操作成功!',
      });
      this.getTableData();
    },
    handleAdd() {
      this.$refs.details.init({ title: '新增规则' });
    },
    handleEdit(rowData) {
      this.$refs.details.init({ title: '编辑规则', ...rowData });
    },
    handlePreview(rowData) {
      this.$refs.details.init({ title: '查看规则', ...rowData, isPreview: true });
    },
    handleDetailsSuccess() {
      this.getTableData();
    },
    async loadBatchFieldList() {
      let result = await batchFieldList({});
      result = result || [];
      this.attributeCodes = result.map((item) => ({
        ...item,
        label: item.fieldName,
        value: item.fieldCode,
      }));
    },
  },
};
</script>

<template>
  <div>
    <nh-dialog
      v-model="visible"
      v-loading="loading.handleSave"
      :title="detailsForm.title"
      custom-class="custom-dialog_50"
      :before-close="handleClose"
      :track="{
        name_zh: `配置管理/批属性规则/弹窗-${
          !!isPreview ? '查看': (isEdit ? '编辑': '新增')}`,
        trackName: $route.path
      }"
    >
      <PlForm
        v-model="detailsForm"
        mode="normal"
        :fields="formFileds"
        :collapse="false"
        submit-btn-text="保 存"
        reset-btn-text="取 消"
        :show-operation="!detailsForm.isPreview"
        :form-props="{
          disabled: !!detailsForm.isPreview,
          rules: rules
        }"
        @submit="handleSave"
        @reset="handleClose"
      >
        <template #shipperId>
          <nh-shipper-selector
            v-model:shipperId="detailsForm.shipperId"
            :disabled="!!detailsForm.isPreview"
            @change="handleChangeShipper"
          />
        </template>
        <template #customerDefaultRule>
          <el-checkbox
            v-model="detailsForm.customerDefaultRule"
            :disabled="!!detailsForm.isPreview"
          >
            货主默认规则
          </el-checkbox>
        </template>
        <template #batchAttribute>
          <el-table :data="detailsForm.batchAttribute" class="width-100">
            <el-table-column prop="b" label="批属性">
              <template #default="scope">
                <el-select
                  v-model="scope.row.fieldCode"
                  placeholder="请选择"
                  :disabled="!!detailsForm.isPreview"
                  @change="fieldCodeChange"
                >
                  <el-option
                    v-for="item in batchAttributes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    :disabled="item.selected"
                  />
                </el-select>
                <div v-show="cantDelete(scope.row)" class="warning-box">
                  温馨提示:当生产日期和到期日期同时存在时不可删除生产日期
                </div>
              </template>
            </el-table-column>
            <!-- 操作栏 -->
            <el-table-column
              v-if="!detailsForm.isPreview"
              width="150"
              label="操作"
            >
              <template #default="scope">
                <nh-button
                  :disabled="cantDelete(scope.row)"
                  type="text"
                  @click="handleDelete(scope.row)"
                >
                  删除
                </nh-button>
              </template>
            </el-table-column>
            <!-- / 操作栏 -->
          </el-table>
          <div v-if="addButtonShow()" class="footer">
            <nh-button
              type="text"
              icon="el-icon-plus"
              @click="handleAdd"
            >
              增加自定义批属性
            </nh-button>
          </div>
        </template>
      </PlForm>
    </nh-dialog>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import TABLECOLUMN, { formFileds } from './fileds';
import {
  ruleBatchAttributeInsert, ruleBatchAttributeUpdate,
} from '../api';

const expireDate = 'expireDate';
// 兼容开发和qa环境上到期日期不统一
const EXPIRE_DATE = 'expire_date';
const productDate = 'productDate';
export default {
  name: 'WarehouseDetails',
  components: {},
  filters: {},
  mixins: [loadingMixin],
  props: {
    attributeCodes: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['success'],
  data() {
    return {
      isPreview: true,
      isEdit: false,
      TABLECOLUMN,
      formFileds,
      StoreData: {},
      detailsForm: {
        batchAttribute: [],
        ruleCode: '',
        ruleName: '',
        description: '',
        customerDefaultRule: false,
      },
      shipperOptions: [],
      rules: {
        batchAttribute: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (value.length <= 0) {
                callback(new Error('请选择至少一个规则'));
                // 一个规则 可以配置多个批属性，此处判定是 判定 多个批属性中，每一个批属性都必须 要有fieldCode 字段
              }
              if (!value.every((item) => !!item.fieldCode)) {
                callback(new Error('已有规则必须选择规则编码'));
              } else {
                callback();
              }
            },
          },
        ],
        ruleCode: [{ required: true, message: '请输入规则编码', trigger: 'blur' }],
        ruleName: [{ required: true, message: '请输入规则名称', trigger: 'blur' }],
        description: [{ required: true, message: '请输入备注', trigger: 'blur' }],
      },
      visible: false,
      batchAttributesEnum: {},
      batchAttributes: [],
      loading: {
        handleSave: false,
      },
    };
  },
  watch: {
    'detailsForm.batchAttribute': {
      handler(newValue) {
        const selectedBatchAttributeCodes = newValue.map((item) => item.fieldCode);
        this.batchAttributes = this.attributeCodes.map((item) => ({
          ...item,
          selected: selectedBatchAttributeCodes.includes(item.value),
        }));
      },
      deep: true,
    },
  },
  created() {
    this.buildbatchAttributes();
  },
  methods: {
    buildbatchAttributes() {
      if (this.attributeCodes && this.attributeCodes instanceof Array) {
        this.batchAttributes = this.attributeCodes.map((item) => ({ ...item, selected: false }));
      }
    },
    addButtonShow() {
      const isAdd = this.batchAttributes && this.batchAttributes.every((item) => item.selected);
      if (this.detailsForm.isPreview) {
        return false;
      }
      if (isAdd) {
        return false;
      }
      return true;
    },
    /**
     * 因表单的 select 只能取到  value ，所以这个函数的作用是把字典转换为 map ，然后使用 value 匹配map的key对应的value
     */
    batchAttributesToEnum() {
      this.attributeCodes.forEach((item) => {
        this.batchAttributesEnum[item.value] = item;
      });
    },
    init(info) {
      this.isPreview = !!info.isPreview;
      if (info) {
        this.isEdit = !!info.shipperId;
        this.shipperOptions = [{
          shipperName: info.shipperName,
          shipperId: info.shipperId,
          shipperCode: info.shipperCode,
        }];
        // 保证新增的时候 不会让 属性 batchAttribute 为空 导致 push 报错
        this.detailsForm = {
          ...this.detailsForm,
          ...info,
        };
      }
      this.visible = true;
    },
    handleClose() {
      this.shipperOptions = [];
      this.detailsForm = {
        batchAttribute: [],
        ruleCode: '',
        ruleName: '',
        description: '',
        customerDefaultRule: false,
      };
      this.visible = false;
    },
    handleDelete(row) {
      // 使用filter 来替代删除 保证 删除动作的正确性
      this.detailsForm.batchAttribute = this.detailsForm.batchAttribute.filter(
        (item) => row.fieldCode !== item.fieldCode,
      );
    },
    cantDelete(row) {
      const haveExpireDate = this.detailsForm.batchAttribute
        .some((item) => item.fieldCode === expireDate || item.fieldCode === EXPIRE_DATE);
      if (row.fieldCode === productDate && haveExpireDate) {
        return true;
      }
      return false;
    },
    handleAdd() {
      this.detailsForm.batchAttribute.push({
        fieldCode: '',
        fieldName: '',
      });
    },
    fieldCodeChange(val) {
      //   判断可选项中是否有生产日期
      const productDateUsable = this.batchAttributes.some((item) => item.fieldCode === productDate);
      //   判断是否已经有生产日期
      const haveProductDate = this.detailsForm.batchAttribute
        .some((item) => item.fieldCode === productDate);
        // 如果选择了到期日期，并且没有生产日期且批属性可选项有生产日期时    自动选择生产日期
      if ((val === expireDate || val === EXPIRE_DATE) && !haveProductDate && productDateUsable) {
        this.detailsForm.batchAttribute.push({
          fieldCode: productDate,
        });
      }
    },
    // 选择货主
    handleChangeShipper(val) {
      this.detailsForm.shipperCode = val?.customerCode || null;
      this.detailsForm.shipperName = val?.shipperName || null;
    },
    async handleSave() {
      this.batchAttributesToEnum();
      this.detailsForm.batchAttribute = this.detailsForm.batchAttribute.map((item) => {
        const fieldName = this.batchAttributesEnum[item.fieldCode]
            && this.batchAttributesEnum[item.fieldCode].label;
        const component = this.batchAttributesEnum[item.fieldCode]
            && this.batchAttributesEnum[item.fieldCode].component;
        return { ...item, fieldName, component };
      });
      if (this.detailsForm.id) {
        await ruleBatchAttributeUpdate(this.detailsForm);
        this.$message({
          type: 'success',
          message: '修改成功!',
        });
      } else {
        await ruleBatchAttributeInsert(this.detailsForm);
        this.$message({
          type: 'success',
          message: '新增成功!',
        });
      }
      this.handleClose();
      this.$emit('success');
    },
  },
};
</script>
<style lang="scss" scoped>
.details-page {
  min-width: 768px;
}

.store_info {
  margin-top: 20px;
}

.body {
  padding-left: 40px;
}

.footer,
.from-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
}

.from-footer {
  justify-content: flex-end;
}

.width-100 {
  width: 100%;
}

.warning-box {
  color: #e6a23c;
}
</style>

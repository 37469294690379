import { FormType } from '@/constant/form';
// 列为枚举
export const STATUS_ENUM = {
  DISABLED: 'DISABLED',
  ENABLED: 'ENABLED',
};
// 将枚举转换为 列表 方便 控件使用
const STATUS_LIST = [{
  label: '生效',
  value: STATUS_ENUM.ENABLED,
}, {
  label: '失效',
  value: STATUS_ENUM.DISABLED,
}];
const STATUS_LIST_ENUM = STATUS_LIST
  .reduce((total, item) => ({ ...total, [item.value]: item.label }), {});
export const TABLECOLUMN = [{
  label: '规则编码',
  prop: 'ruleCode',
  minWidth: 180,
}, {
  label: '规则名称',
  prop: 'ruleName',
  minWidth: 180,
}, {
  label: '指定货主',
  prop: 'shipperName',
  minWidth: 180,
}, {
  label: '货主默认规则',
  prop: 'customerDefaultRule',
  minWidth: 180,
  formatter: (row) => (row.customerDefaultRule ? '是' : '否'),
}, {
  label: '状态',
  prop: 'status',
  minWidth: 180,
  formatter: (row) => STATUS_LIST_ENUM[row.status],
}, {
  label: '备注',
  prop: 'description',
  minWidth: 180,
}, {
  label: '操作',
  prop: 'action',
  fixed: 'right',
  width: 180,
}];
export const serchFields = [
  {
    label: '规则名称',
    prop: 'ruleName',
    component: FormType.INPUT,
  }, {
    label: '状态',
    prop: 'status',
    component: FormType.SELECT,
    options: STATUS_LIST,
  }, {
    label: '指定货主',
    prop: 'shipperId',
  },
];
